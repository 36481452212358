<template>
  <form>
    <div class="rowForm">
      <div class="rowItem">
        <label>Email address</label>
        <input placeholder="Recipient's email address" />
      </div>
      <div class="rowItem">
        <label></label>
        <div class="btnInput">
          <button type="submit" class="clickable">Invite</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "Invite",
  data() {
    return {};
  }
};
</script>
