<template>
  <div class="profileTab">
    <div class="settingsCont">
      <div class="settingsBlock">
        <h5 class="or-underline">Create a new team</h5>

        <div class="detailsForm">
          <!-- <form>
            <div class="rowForm">
              <div class="rowItem">
                <label>Team Name</label>
                <input placeholder="e.g. Product Marketing" />
              </div>
              <div class="rowItem">
                <label>Team type</label>
                <select
                  name="timezone"
                  id="timezone"
                  aria-placeholder="Team type"
                  class="thiner"
                >
                  <option value="0">Team type</option>
                  <option value="+1">Team</option>
                  <option value="+2">Sub-team</option>
                </select>
              </div>
              <div class="rowItem">
                <label>Where to place it</label>
                <select
                  name="timezone"
                  id="timezone"
                  aria-placeholder="Select a location"
                  class="thiner"
                >
                  <option value="0">Location</option>
                  <option value="+1">Sales</option>
                  <option value="+2">Marketing</option>
                  <option value="+3">Design</option>
                  <option value="+4">Product</option>
                  <option value="+5">Engineering</option>
                </select>
              </div>
              <div class="rowItem">
                <label></label>
                <div class="btnInput">
                  <button type="submit" class="clickable">
                    Create team
                  </button>
                </div>
              </div>
            </div>
          </form> -->
          <NewTeam />
        </div>
      </div>
      <div class="settingsBlock">
        <h5 class="or-underline">Manage teams</h5>

        <div class="table">
          <div class="tr th teamsTitles">
            <div class="td" style="flex-grow: 1;">Teams</div>
            <div class="td" style="flex-grow: 1;">People</div>
            <div class="td" style="flex-grow: 2;">Spaces they can see</div>
            <div class="td" style="flex-grow: 0.25;">Edit</div>
          </div>
          <div v-if="teams">
            <TeamList :teams="teams" />
          </div>

          <!-- <div class="tr">
            <div class="td teamsLogo" style="flex-grow: 1;">
              <h5>Marketing</h5>
            </div>
            <div class="editBtn td"></div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TeamList from "@/components/settings/workspace/TeamList.vue";
import NewTeam from "@/components/commons/NewTeam.vue";
import getCollection from "@/composables/getCollection";

export default {
  components: {
    TeamList,
    NewTeam
  },
  setup() {
    const { error, documents: teams } = getCollection("teams");
    return {
      error,
      teams
    };
  }
};
</script>
