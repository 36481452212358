<template>
  <LeftNav />
  <NewNav />
  <div class="container">
    <div class="wrapper">
      <div class="titlesBlock">
        <div class="greetings">Let’s get down to the details</div>
        <h1 class="title">
          <router-link :to="{ name: 'Settings' }">Personal</router-link> /
          <router-view :to="{ name: 'SettingsWork' }">Workspace</router-view>
        </h1>
      </div>

      <div>
        <div class="tabbedNav">
          <button
            v-for="tab in tabs"
            :key="tab"
            @click="selected = tab"
            :class="['tab-btn clickable', { active: selected === tab }]"
          >
            {{ tab }}
          </button>
        </div>

        <component :is="selected" class="tab"></component>
      </div>
    </div>
  </div>
</template>

<script>
import LeftNav from "@/components/navigations/LeftNav.vue";
import NewNav from "@/components/navigations/NewNav.vue";
import People from "@/views/settings/workplace/People.vue";
import Teams from "@/views/settings/workplace/Teams.vue";

export default {
  data() {
    return {
      tabs: ["People", "Teams"],
      selected: "People"
    };
  },
  components: {
    LeftNav,
    NewNav,
    People,
    Teams
  }
};
</script>
