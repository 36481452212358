<template>
  <div class="profileTab">
    <div class="settingsCont">
      <div class="settingsBlock">
        <h5 class="or-underline">Invite a new member</h5>
        <div class="detailsForm">
          <Invite />
        </div>
      </div>

      <div class="settingsBlock">
        <h5 class="or-underline">Manage members</h5>

        <div class="table">
          <div class="tr th teamsTitles">
            <div class="td" style="flex-grow: 1.5;">Name</div>
            <div class="td" style="flex-grow: 1.5;">Departments</div>
            <div class="td">Role</div>
            <div class="td">Email</div>
            <div class="td">Workspaces</div>
            <div class="td">Last Active</div>
          </div>

          <div v-if="people">
            <PeopleList :users="people" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Invite from "@/components/Invite.vue";
import PeopleList from "@/components/settings/workspace/PeopleList.vue";
import getCollection from "@/composables/getCollection";

export default {
  components: {
    Invite,
    PeopleList
  },
  setup() {
    const { error, documents: people } = getCollection("users");
    return {
      error,
      people
    };
  }
};
</script>
