<template>
  <div class="tr" v-for="team in teams" :key="team.id">
    <div class="td teamsLogo" style="flex-grow: 1;">
      <h6>{{ team.name }}</h6>
    </div>
    <div class="td" style="flex-grow: 1;">
      <div class="members">
        <div class="memberBox membersBoxSmall">
          <div class="oneMember oneMemberSmall">M</div>
        </div>
        <div class="memberBox membersBoxSmall">
          <div class="oneMember oneMemberSmall">B</div>
        </div>
        <div class="memberBox membersBoxSmall">
          <div class="oneMember oneMemberSmall">C</div>
        </div>
      </div>
    </div>
    <div class="td teamsTags" style="flex-grow: 2;">
      <div class="tagTeam clickable">Marketing</div>
      <div class="tagTeam clickable">Design</div>
    </div>
    <div class="editBtn td" style="flex-grow: 0.25;"></div>
  </div>
</template>

<script>
export default {
  props: {
    teams: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
h6 {
  margin: 0;
  padding-left: 12px;
}
.members {
  position: inherit;
}
</style>
