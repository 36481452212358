<template>
  <div class="tr" v-for="user in users" :key="user.id">
    <div class="td teamsLogo" style="flex-grow: 1.5;">
      <img src="@/assets/imgs/anders.png" class="logoAvatar" />
      <h5>{{ user.displayName }} {{ user.name }}</h5>
    </div>
    <div class="td teamsTags" style="flex-grow: 1.5;">
      <div class="tagTeam clickable">Marketing</div>
    </div>
    <div class="roleTag roleAdmin td">Admin</div>
    <div class="td">
      <span>{{ user.email }}</span>
    </div>
    <div class="td">
      <span>40hrs/wk</span>
    </div>
    <div class="editBtn td"></div>
  </div>
</template>

<script>
export default {
  props: {
    users: {
      type: Object,
      required: true
    }
  }
};
</script>

<style></style>
