<template>
  <div v-if="showForm">
    <form @submit.prevent="handleSubmit">
      <div class="form-group">
        <label>Team Name</label>
        <input type="text" class="form-control" v-model="name" required />
      </div>
      <BaseButton @click="closeOnSave = false" modifiers="primary">
        Create Team
      </BaseButton>
    </form>
    <button v-if="showForm" @click="showForm = false">Cancel</button>
  </div>
  <div v-else>
    <div class="newProject clickable" @click="showForm = true">
      Add a new team
    </div>
  </div>
</template>

<script>
import getUser from "@/composables/getUser";
import BaseButton from "@/components/designsystem/BaseButton";
import { ref } from "vue";
import { db, timestamp } from "@/firebase";

export default {
  components: { BaseButton },
  setup() {
    const { user } = getUser();

    const name = ref("");

    const showForm = ref(false);

    const handleSubmit = async () => {
      const project = {
        name: name.value,
        createdAt: timestamp(),
        userId: user.value.uid
      };
      const res = await db.collection("teams").add(project);
      console.log(res);
      name.value = "";
    };
    return {
      name,
      handleSubmit,
      showForm
    };
  }
};
</script>
<style lang="scss" scoped>
.newProject {
  align-items: center;
  background: $base-darkbeige;
  border-radius: 3px;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 336px;
}
.newProject:hover {
  background: $base-color;
  color: white;
}
</style>
